import tw from 'clsx';
import Link, { LinkProps } from 'next/link';
import { ComponentProps, FC, PropsWithChildren, ReactElement, useRef } from 'react';
import { useButton } from 'react-aria';

import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import ChevronRightIcon from './bx-chevron-right.svg';

export const BUTTON_VARIANTS = {
  primary: tw(
    'bg-accent-primary text-accent-object-primary transition-all',
    'hover:bg-accent-surface-state1',
    'active:bg-accent-surface-state2',
    'disabled:bg-accent-surface-state3',
  ),
  basic: tw(
    'border border-blue-80 bg-white text-blue-80 transition-all',
    'hover:border-blue-90 hover:bg-gray-5 hover:text-blue-90',
    'active:border-blue-100 active:bg-gray-10 active:text-blue-100',
    'disabled:border-gray-10 disabled:text-gray-10',
  ),
  subtle: tw(
    'bg-white text-blue-80 transition-all',
    'hover:bg-gray-5',
    'active:bg-gray-10',
    'disabled:text-gray-10',
  ),
  link: tw(
    'text-gray-100 transition-none',
    'hover:text-blue-90 [&:not(:disabled)]:hover:underline',
    'active:text-blue-100',
    'disabled:text-gray-10',
  ),
  danger: tw(
    'border border-red-80 bg-white text-red-80 transition-all',
    'hover:border-red-90 hover:bg-gray-5 hover:text-red-90',
    'active:border-red-100 active:bg-gray-10 active:text-red-100',
    'disabled:border-red-20 disabled:text-red-20',
  ),
  _plane: '',
};

export type ButtonProps = PropsWithChildren & {
  className?: string;
  variant?: keyof typeof BUTTON_VARIANTS;
  icon?: ReactElement;
  isIconOnly?: boolean;
  isDisabled?: boolean;
  isShowArrowIcon?: boolean;
  /** 指定するとbuttonタグではなく、next/linkを使用する */
  link?: ComponentProps<typeof Link>;
  /** 指定するとbuttonタグではなく、aタグを使用する */
  externalLink?: HtmlTagProps['a'];
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large' | 'x-large';
};

export function Button(props: ButtonProps) {
  const { variant = 'primary', size = 'large', isDisabled, onClick } = props;
  const ref = useRef<Element>(null);

  const linkProps: {} = props.link || props.externalLink || {};
  const ButtonTag = props.link
    ? (Link as FC<unknown>)
    : props.externalLink
    ? ('a' as const)
    : ('button' as const);

  /** @refs https://react-spectrum.adobe.com/react-aria/useButton.html */
  const { buttonProps } = useButton({ elementType: ButtonTag, isDisabled, onPress: onClick }, ref);

  const sizeClasses = tw(
    size === 'small' && 'h-[40px] p-s text-[13px]',
    size === 'medium' && 'h-[48px] px-xl py-m text-[13px]',
    size === 'large' && 'h-[50px] px-xxl py-m text-[14px]',
    size === 'x-large' && 'h-[50px] px-xxl py-m text-[18px]',
  );

  return (
    <ButtonTag
      ref={ref as any}
      {...buttonProps}
      {...linkProps}
      className={tw(
        'flex select-none items-center justify-center gap-xxs rounded-corner-pill text-label-large font-bold leading-6 outline-none',
        'disabled:cursor-default',
        props.isIconOnly && 'aspect-square',
        BUTTON_VARIANTS[variant],
        props.className,
        sizeClasses,
      )}
    >
      {props.icon}
      {!props.isIconOnly && <div>{props.children}</div>}
      {props.isShowArrowIcon && !props.isIconOnly && <ChevronRightIcon />}
    </ButtonTag>
  );
}
