import tw from 'clsx';
import Router from 'next/router';
import { ReactElement } from 'react';
import { BiPlus } from 'react-icons/bi';

import { CurrentSessionT } from '~/_legacy/api/Session';

import { Avatar } from '~/components/Avatar/Avatar';
import { Button } from '~/components/Button/Button';
import { SusnetLink } from '~/components/_wrapper/SusnetLink';
import { useToggle } from '~/hooks/useToggle';
import { authToken } from '~/utils';

import { NavigationBarList } from './NavigationBarList';

import Logo from '@public/images/logo.svg';
import IconMenu from '@public/new-icons/icon/icon_menu.svg';

/** ログイン専用のページをここに指定し、ログアウト時はトップページに戻るようにしている */
const LOGIN_PAGE_PATH = ['/corp-admin', '/rooms'];

type Props = {
  currentUser: CurrentSessionT | undefined | null;
};

export function NavigationBar({ currentUser }: Props) {
  const [isShowNavMenu, toggleIsShowNavMenu] = useToggle();
  const [isShowAccountMenu, toggleIsShowAccountMenu] = useToggle();

  /** NOTE: 管理画面への遷移先 */
  function adminPath() {
    return '/admin';
  }

  /** NOTE: ログアウト時の挙動 */
  function onClickLogOut() {
    authToken.delete();
    if (LOGIN_PAGE_PATH.some((it) => Router.asPath.includes(it))) {
      location.href = '/';
    } else {
      location.reload();
    }
  }

  const ModalNavigationMenu = () => {
    if (currentUser === undefined) return null;

    return (
      <div className='flex h-[calc(100vh-24px)] w-[calc(100vw-24px)] max-w-[356px] flex-col rounded-corner-l bg-white px-xl__sp shadow-elevation-level2'>
        <div className='flex h-[56px] items-center gap-s__sp'>
          <div className='cursor-pointer' onClick={toggleIsShowNavMenu}>
            <BiPlus className='h-5 w-5 rotate-45' />
          </div>
        </div>

        <div className='flex h-full w-full flex-col items-center justify-between gap-l__sp py-xl__sp'>
          {/* NOTE: NavItem */}
          <ul className='flex w-full flex-col gap-s__sp'>
            {NavigationBarList.map((item, index) => (
              <li key={`nav-${index}`}>
                <Button link={{ href: item.path }} variant='link'>
                  {item.name}
                </Button>
              </li>
            ))}
          </ul>

          {currentUser === null ? (
            <Button variant='primary' link={{ href: '/login' }} className='flex lg:hidden'>
              会員登録・ログイン
            </Button>
          ) : (
            <Button variant='link' onClick={onClickLogOut}>
              ログアウト
            </Button>
          )}
        </div>
      </div>
    );
  };

  const ModalAccountMenu = () => {
    if (currentUser === undefined || currentUser === null) return null;

    return (
      <div className='mt-3 flex w-[calc(100vw-24px)] max-w-[356px] flex-col rounded-corner-l bg-white px-xl__sp shadow-elevation-level2 lg:mt-0 lg:max-w-[240px] lg:p-l'>
        {/* ModalHeader */}
        <div className='flex h-[56px] items-center gap-s__sp lg:hidden'>
          <div className='cursor-pointer' onClick={toggleIsShowAccountMenu}>
            <BiPlus className='h-10 w-10 rotate-45' />
          </div>
          <div className='w-[111px]'>
            <Logo />
          </div>
        </div>

        {/* ModalBody */}
        <div className='flex flex-col items-center gap-l__sp px-0 py-xl__sp lg:gap-0 lg:py-0'>
          <div className='flex w-full flex-col items-center gap-s__sp pb-l__sp pt-xs__sp lg:gap-s lg:pb-l lg:pt-xs'>
            <Avatar
              size={64}
              src={currentUser.image() || '/icons/org-icon.png'}
              alt={currentUser.name}
            />

            {currentUser.type === 'org' || currentUser.type === 'corporate' ? (
              <SusnetLink className='flex flex-col items-center' href={adminPath()}>
                <div className='text-body-large font-bold text-primary'>{currentUser.name}</div>
                <div className='text-body-small text-regular'>管理画面</div>
              </SusnetLink>
            ) : (
              <SusnetLink className='flex flex-col items-center' href={'/user'}>
                <div className='text-body-large font-bold text-primary'>{currentUser.name}</div>
              </SusnetLink>
            )}
          </div>

          <Button variant='link' onClick={onClickLogOut}>
            ログアウト
          </Button>
        </div>
      </div>
    );
  };

  const NavigationMenu = ({
    children,
    className,
  }: {
    children: ReactElement;
    className?: string;
  }) => {
    return (
      <div className='relative z-30 lg:hidden'>
        <div
          className={tw(
            'fixed flex h-screen w-screen justify-center overflow-hidden bg-black/50',
            className,
          )}
        >
          {children}
        </div>
      </div>
    );
  };

  const NavigationBarLeft = () => {
    return (
      <div className='flex flex-1 items-center justify-start gap-x-s'>
        <div className='block cursor-pointer lg:hidden' onClick={toggleIsShowNavMenu}>
          <IconMenu />
        </div>
        <SusnetLink href={'/'} className='w-[111px] lg:w-[140px]'>
          <Logo />
        </SusnetLink>
        <nav className='hidden lg:block'>
          <ul className='flex flex-row items-center justify-start gap-xxs'>
            {NavigationBarList.map((item, index) => (
              <li key={`nav-${index}`}>
                <Button variant='link' link={{ href: item.path }}>
                  {item.name}
                </Button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    );
  };

  const NavigationBarRight = () => {
    /**
     * NOTE: CurrentUserStateの値が↓のようになっているため、undefined/null 別々の分岐を実装しなければいけない
     * - undefined: 初期値、api未呼び出し
     * - null     : api呼び出し後、ログインしていない時の値
     */
    if (currentUser === undefined) return null;
    if (currentUser === null) {
      return (
        <Button variant='primary' link={{ href: '/login' }} className='hidden lg:flex'>
          会員登録・ログイン
        </Button>
      );
    }
    return (
      <div
        className='relative flex cursor-pointer items-center justify-end'
        onClick={toggleIsShowAccountMenu}
      >
        <Avatar
          size={32}
          src={currentUser.image() || '/icons/org-icon.png'}
          alt={currentUser.name}
        />

        {isShowAccountMenu && (
          <div className='absolute right-[-40px] top-16 hidden lg:block'>
            <ModalAccountMenu />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='relative h-full w-full'>
      {/* NOTE: ModalNavigationは、スマホ時のみの表示です */}
      {isShowNavMenu && (
        <NavigationMenu className='items-center'>
          <ModalNavigationMenu />
        </NavigationMenu>
      )}

      {/* NOTE: ModalAccountMenuは、スマホ時のみの表示です */}
      {isShowAccountMenu && (
        <NavigationMenu className='items-start'>
          <ModalAccountMenu />
        </NavigationMenu>
      )}

      <div className='relative z-20 h-[80px] w-full lg:h-[112px]'>
        <div className='fixed left-1/2 top-3 z-20 flex h-[56px] w-[calc(100%-24px)] max-w-[1200px] translate-x-[-50%] items-center justify-between rounded-corner-pill bg-primary px-xl shadow-elevation-level2 lg:top-4 lg:h-[80px] lg:px-[40px]'>
          <NavigationBarLeft />
          <NavigationBarRight />
        </div>
      </div>
    </div>
  );
}
