import { useRecoilValue } from 'recoil';

import { Footer } from '~/components/Footer/Footer';
import { NavigationBar } from '~/components/NavigationBar/NavigationBar';
import { NavigationBarAdmin } from '~/components/NavigationBar/NavigationBarAdmin';
import { NavigationRail } from '~/components/NavigationRail/NavigationRail';
import { OnlyWeb } from '~/components/_for-next/OnlyWeb';
import { WithNextSeo } from '~/components/_for-next/WithNextSeo';
import { CurrentUserState } from '~/state/currentUser';

type Props = {
  children: React.ReactNode;
  pageTitle: string;
  pageDescription?: string;
  pageImage?: string;
  pageUrl?: string;
  pageNoIndex?: boolean;
  isAdminPage?: boolean;
};

export default function Layout({
  children,
  pageTitle,
  pageDescription = '',
  pageImage = '',
  pageUrl = '',
  pageNoIndex = false,
  isAdminPage = false,
}: Props) {
  const currentUser = useRecoilValue(CurrentUserState);
  return (
    <WithNextSeo
      title={pageTitle}
      description={pageDescription}
      image={pageImage}
      url={pageUrl}
      noindex={pageNoIndex}
    >
      <OnlyWeb>
        {!isAdminPage && (
          <div className='flex min-h-screen w-screen flex-col items-center bg-primary'>
            <NavigationBar currentUser={currentUser} />
            {children}
          </div>
        )}

        {isAdminPage && (
          <div className='flex h-screen w-screen flex-col'>
            <NavigationBarAdmin currentUser={currentUser} />
            <div className='mt-20 grid h-[calc(100dvh-80px)] grid-cols-[56px,1fr]'>
              <NavigationRail isOrg={currentUser?.type === 'org'} />
              <div className='flex flex-col items-center bg-primary'>{children}</div>
            </div>
          </div>
        )}

        {!isAdminPage && <Footer />}
      </OnlyWeb>
    </WithNextSeo>
  );
}
