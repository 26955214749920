import { FC } from 'react';

import { HtmlTagProps } from '~/utils/types/HtmlTagProps';

import IconHome from '@public/new-icons/filled-icon/NavigationRail/icon_home.svg';
import IconMessage from '@public/new-icons/filled-icon/NavigationRail/icon_message.svg';
import IconOrganization from '@public/new-icons/filled-icon/NavigationRail/icon_organization.svg';
import IconProject from '@public/new-icons/filled-icon/NavigationRail/icon_project.svg';
import IconReport from '@public/new-icons/filled-icon/NavigationRail/icon_report.svg';
import IconSetting from '@public/new-icons/filled-icon/NavigationRail/icon_setting.svg';

export type LinkItem = {
  name: string;
  path: string;
  Icon?: FC<HtmlTagProps['svg']>;
  isActive(currentPath: string): boolean;
};

function getPath(it?: string) {
  return `/admin${it ?? ''}`;
}

export const getNavigationRailList = ({ isOrg }: { isOrg: boolean }): LinkItem[] => {
  if (isOrg) {
    return [
      // { name: 'ホーム', path: getPath(), Icon: IconHome, isActive },
      {
        name: 'プロジェクト',
        path: getPath('/project/challenge'),
        Icon: IconProject,
        isActive: (currentPath) => currentPath.includes(getPath('/project')),
      },
      { name: '活動報告', path: getPath('/posts'), Icon: IconReport, isActive },
      { name: '組織情報', path: getPath('/org'), Icon: IconOrganization, isActive },
      // { name: 'メッセージ', path: getPath('/rooms'), Icon: IconMessage, isActive },
      // { name: '設定', path: getPath('/settings'), Icon: IconSetting, isActive },
    ];
  }

  return [
    // { name: 'ホーム', path: getPath(), Icon: IconHome, isActive },
    {
      name: 'プロジェクト',
      path: getPath('/project/challenge'),
      Icon: IconProject,
      isActive: (currentPath) => currentPath.includes(getPath('/project')),
    },
    { name: '活動報告', path: getPath('/posts'), Icon: IconReport, isActive },
    // { name: 'メッセージ', path: getPath('/rooms'), Icon: IconMessage, isActive },
    // { name: '設定', path: getPath('/settings'), Icon: IconSetting, isActive },
  ];
};

function isActive(this: LinkItem, currentPath: string) {
  return this.path === currentPath;
}
